import { graphql } from '@api/gql/gql'

export const getOrders = graphql(`
    query getOrders($input: OrdersApiInput!, $pagination: PaginationInput!) {
        orders(input: $input, pagination: $pagination) {
            items {
                id
                peecho_id
                peecho_status
                stripe_id
                status
                moderator_id
                moderation_status
                total
                track_code
                track_url
                parent_id
                paid_at
                created_at
                comment
                
                user {
                    id
                    name
                }
                
                addresses {
                    id
                    type
                    country
                    city
                    line1
                    line2
                    postal_code
                    state
                    email
                    name
                    phone
                }
                
                moderator {
                    id
                    name
                    role
                }
                
                albums {
                    id
                    title
                    subtitle
                    from_at
                    to_at
                    page_format
                    book_status
                    spine_status
                    cover_status
                    book {
                        id
                        uuid
                    }
                    spine {
                        id
                        uuid
                    }
                    cover {
                        id
                        uuid
                    }
                    quantity
                }
            }
            page_info {
                page
                limit
                total
            }
        }
    }
`)
