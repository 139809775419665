import { useState } from 'react'
import { AlertError } from '@constants/alert-error'
import { AuthorizationError } from '@constants/authorization-error'
import { getLoginUrl } from '@constants/link'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'

export const useHelpers = () => {
  const { openModal } = useModal()

  const [isRedirectLogin, setIsRedirectLogin] = useState(false)

  const formatSubtitle = (fromYear: number, toYear: number): string => {
    if (fromYear === toYear) {
      return `${fromYear}`
    }

    return `${fromYear} - ${toYear}`
  }

  // Подтверждение
  const onConfirm = (title: string, description: string): Promise<null> => {
    return new Promise((resolve) => {
      openModal({
        type: ModalType.CONFIRM_MODAL,
        title,
        message: description,
        isDismissable: false,
        hideCloseButton: true,
        onConfirm: () => resolve(null),
      })
    })
  }

  // Обработка ошибок
  const handleError = (e: unknown) => {
    if (e instanceof AlertError) {
      openModal({
        type: ModalType.ALERT_MODAL,
        title: e.message,
        description: e.getText(),
        link: e.getLink(),
      })
    } else if (e instanceof AuthorizationError) {
      setIsRedirectLogin(true)
      window.location.href = getLoginUrl()
    }
  }

  return {
    formatSubtitle,
    onConfirm,
    handleError,
    isRedirectLogin,
  }
}
