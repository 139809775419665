import { FC, useMemo } from 'react'
import {
  Album,
  AlbumPage,
  AlbumPageType,
  CoverColor,
  ImageSize,
  PageFormat,
} from '@api/gql/graphql'
import { AlbumPagePadding, parsePageFormat } from '@constants/album'
import {
  AlbumAdditional,
  AlbumBackCover,
  AlbumFrontCover,
  AlbumIntro,
  AlbumPicture,
  PageLayout,
  WindowSizesContext,
} from '@share/album'
import { WindowSizes } from '@share/album/types'
import { clsx } from 'clsx'

import { coverPagePadding, defaultPagePadding } from '../types'

type Props = {
  album: Album;
  albumPage: AlbumPage;
};

export const PageItem: FC<Props> = (props) => {
  const pageFormat = props.album?.page_format || PageFormat.Format_210x210
  const pagePadding: AlbumPagePadding = props.albumPage.type === AlbumPageType.FrontCover
    ? coverPagePadding : defaultPagePadding

  const outer = useMemo(() => parsePageFormat(pageFormat), [pageFormat])
  const inner = useMemo(() => parsePageFormat(pageFormat, pagePadding), [pageFormat])

  const windowSizes = useMemo((): WindowSizes => ({
    inner,
    outer,
    padding: pagePadding,
    outerHalf: { width: 0, height: 0 },
    innerHalf: { width: 0, height: 0 },
  }), [outer, inner])

  const getImageSize = (imagesCount: number): ImageSize => {
    // Выдаем в dev окружении низкое качество. Чтобы быстро обрабатывалось и весил меньше
    if (import.meta.env.DEV) {
      return ImageSize.Small
    }

    if (imagesCount >= 2) {
      return ImageSize.Medium
    }

    return ImageSize.Large
  }

  const pageLayoutClassName = useMemo(() => {
    const isCover = [
      AlbumPageType.FrontCover,
      AlbumPageType.BackCover,
    ].includes(props.albumPage.type as AlbumPageType)

    const colorClassName = isCover ? clsx(
      props.album.cover_color === CoverColor.White && 'bg-white',
      props.album.cover_color === CoverColor.Red && 'bg-cover-red border border-cover-red',
      props.album.cover_color === CoverColor.Beige && 'bg-cover-beige border border-cover-beige',
      props.album.cover_color === CoverColor.Green && 'bg-cover-green border border-cover-green',
      props.album.cover_color === CoverColor.Blue && 'bg-cover-blue border border-cover-blue',
    ) : ''

    return clsx(
      'text-[0.5em] md:text-[1.28em]',
      colorClassName,
    )
  }, [props])

  return (
    <WindowSizesContext.Provider value={windowSizes}>
      <PageLayout
        key={props.albumPage.id}
        className={pageLayoutClassName}
        dev={import.meta.env.DEV}
        isWide
      >
        {props.album && props.albumPage.type === AlbumPageType.FrontCover && (
          <AlbumFrontCover album={props.album} imageSize={ImageSize.Large} />
        )}

        {props.albumPage.type === AlbumPageType.Picture && props.albumPage?.images?.map((image) => (
          <AlbumPicture
            key={image.external_id}
            image={image}
            albumPageId={props.albumPage.id}
            albumId={props.album.id}
            isWide
            pageWidth={props.albumPage.width || 0}
            pageHeight={props.albumPage.height || 0}
            imageSize={getImageSize(props.albumPage?.images?.length || 0)}
            debug
          />
        ))}

        {props.albumPage.type === AlbumPageType.Additional && <AlbumAdditional />}
        {props.albumPage.type === AlbumPageType.BackCover && <AlbumBackCover album={props.album} />}
        {props.albumPage.type === AlbumPageType.Intro && <AlbumIntro />}
      </PageLayout>
    </WindowSizesContext.Provider>

  )
}
