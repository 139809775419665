import { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { toast } from '@components/toast'

import { getOrderCost } from '../api'

type Props = {
  orderId: string
  pagesCount: number
}

export const useOrderCost = (props: Props) => {
  const getOrderCostState = useQuery(getOrderCost, {
    variables: { orderId: props.orderId },
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const total = useMemo(
    () => {
      const prevOrderCost = getOrderCostState.previousData?.order_cost.total
      const nextOrderCost = getOrderCostState.data?.order_cost.total

      return nextOrderCost ?? prevOrderCost ?? 0
    },
    [getOrderCostState.data?.order_cost.total],
  )

  const subtotal = useMemo(
    () => {
      const prevOrderCost = getOrderCostState.previousData?.order_cost.subtotal
      const nextOrderCost = getOrderCostState.data?.order_cost.subtotal

      return nextOrderCost ?? prevOrderCost ?? 0
    },
    [getOrderCostState.data?.order_cost.subtotal],
  )

  useEffect(() => {
    getOrderCostState.refetch({ orderId: props.orderId })
  }, [props.pagesCount])

  return {
    loading: getOrderCostState.loading,
    total,
    subtotal,
  }
}
