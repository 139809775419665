import { CSSProperties, FC, useMemo } from 'react'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'
import currency from 'currency.js'

type Props = {
  isLoading: boolean
  onClick: () => void
  total: number
  subtotal: number
  style?: CSSProperties
}

export const CheckoutButton: FC<Props> = (props) => {
  const totalFormatted = useMemo(
    () => currency((props.total ?? 0) / 100).format({ symbol: '$' }),
    [props.total],
  )

  const subtotalFormatted = useMemo(
    () => currency((props.subtotal ?? 0) / 100).format({ symbol: '$' }),
    [props.subtotal],
  )

  return (
    <div
      style={props.style}
      className="fixed bottom-0 py-4 flex flex-col items-center w-full left-0 px-2 bg-gradient-to-t from-white via-white to-transparent bg-opacity-0"
    >
      <div className="flex gap-4 mb-2 font-medium">
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faTruck} className="text-primary" />
          Free delivery
        </div>
      </div>

      <Button
        className="rounded-full py-7 px-10 text-base"
        color="primary"
        variant="solid"
        isLoading={props.isLoading}
        onClick={props.onClick}
      >
        {`Order book for ${totalFormatted} `}
        <span className="line-through opacity-40">
          {subtotalFormatted}
        </span>
      </Button>
    </div>
  )
}
