import { FC, useMemo } from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'

type Props = {
  text: string
  isSkip?: boolean
  isClose?: boolean
  onSkip?: () => void
  onClose?: () => void
  onClick?: () => void
}

export const Hint: FC<Props> = (props) => {
  const iconClose = useMemo(() => (
    <FontAwesomeIcon
      icon={faClose}
      className="text-default-400 group-hover:text-default-200 transition-colors"
      size="lg"
    />
  ), [])

  return (
    <div className="fixed bottom-2 md:bottom-6 z-50 left-0 w-full pointer-events-none px-2">
      <div className="flex gap-2 rounded-xl bg-default-800 md:w-fit px-6 mx-auto items-center pointer-events-auto">
        <div className="text-white py-3 cursor-pointer" onClick={props.onClick}>
          {props.text}
        </div>

        {props.isSkip && (
          <div
            className="text-default-400 py-3 pl-3 hover:text-default-200 cursor-pointer transition-colors"
            onClick={props.onSkip}
          >
            Skip
          </div>
        )}

        {props.isClose && (
          <div className="cursor-pointer">
            <Button
              variant="light"
              isIconOnly
              className="!bg-default-800 group"
              startContent={iconClose}
              onClick={props.onClose}
            />
          </div>
        )}
      </div>
    </div>
  )
}
