import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { defaultPageFormat } from '@constants/album'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'
import { payOrder } from '@pages/order-page/api'

type Props = {
  orderId: string
  total: number
}

export const usePurchase = (props: Props) => {
  const [payOrderMutation, payOrderState] = useMutation(payOrder, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })

  const onPurchase = () => {
    amplitude.event({
      type: AmplitudeEvent.OrderClickPurchase,
      order_id: props.orderId,
    })

    ga.event({
      category: GaCategory.Order,
      action: GaAction.OrderClickPay,
      value: props.total,
    })

    payOrderMutation({
      variables: { orderId: props.orderId },
    }).then((res) => {
      const paymentUrl = res.data?.pay_order.payment_url
      if (!paymentUrl) {
        return
      }

      facebookPixel.event('AddToCart', {
        currency: 'USD',
        value: res.data?.pay_order.id ?? 0,
        contents: [
          {
            id: defaultPageFormat,
            quantity: 1,
          },
        ],
        content_type: 'product_group',
        event_id: res.data?.pay_order.id ?? '',
      })

      window.location.href = paymentUrl
    })
  }

  return {
    onPurchase,
    isLoading: payOrderState.loading,
  }
}
