import { graphql } from '@api/gql/gql'

export const getOrderOwnLast = graphql(`
    query getOrderOwnLast {
        order_own_last {
            id
            total_images_count
            created_start_at
            created_end_at
            albums {
                id
            }
        }
    }
`)
