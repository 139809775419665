import { HintType } from './hint'

const storeKey = 'current-hint'

export type HintPayload = {
  hintType: HintType
  skipped?: boolean
  closed?: boolean
}

const write = (orderId: string, payload: HintPayload) => {
  const storeValue = localStorage.getItem(storeKey)
  const value = storeValue ? JSON.parse(storeValue) : {}

  localStorage.setItem(storeKey, JSON.stringify({ ...value, [orderId]: payload }))
}

const read = (orderId: string): HintPayload | null => {
  const storeValue = localStorage.getItem(storeKey)
  const value = storeValue ? JSON.parse(storeValue) : {}

  return value[orderId] ?? null
}

const reset = () => {
  localStorage.removeItem(storeKey)
}

export const currentHintStore = {
  write,
  read,
  reset,
}
