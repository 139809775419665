import { graphql } from '@api/gql/gql'

export const getOrder = graphql(`
    query getOrderFromOrderPage($orderId: ID!) {
        order(order_id: $orderId) {
            id
            status
            peecho_status
            created_at
            total
            track_code
            track_url
            albums {
                id
                title
                subtitle
                from_at
                to_at
            }
        }
    }
`)
