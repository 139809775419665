import { Logo } from './logo'

export const AlbumIntro = () => {
  return (
    <div className="flex w-full h-full font-medium leading-none font-album">
      <div className="w-[50%] tracking-tight2 items-center text-[3.45em] font-medium text-dark-gray">
        <div>Easy</div>
        <div>Photo</div>
        <div>Book</div>
      </div>
      <div className="w-[50%] flex items-end justify-end">
        <Logo type="color" className="w-24" />
      </div>
    </div>
  )
}
