export class AlertError extends Error {
  private text: string = ''

  private link?: string = undefined

  setText(text: string) {
    this.text = text
  }

  setLink(link: string) {
    this.link = link
  }

  getText() {
    return this.text
  }

  getLink() {
    return this.link
  }
}
